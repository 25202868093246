<template>
  <v-flex class="wrapper" shrink>
    <v-icon v-if="value == 3" class="rotating">{{ icon }}</v-icon>
    <v-icon v-if="value == 4" color="expressive_green">{{ icon }}</v-icon>
  </v-flex>
</template>

<script>
const NOT_MODIFIED = 0;
const MODIFIED = 1;
const BEFORE_SYNCING = 2;
const SYNCING = 3;
const SYNCED = 4;

export default {
  props: {
    value: {},
  },
  computed: {
    icon() {
      switch (this.value) {
        case SYNCING:
          return "mdi-autorenew";
        case NOT_MODIFIED:
          return "";
        case MODIFIED:
          return "";
        case BEFORE_SYNCING:
          return "";
        case SYNCED:
          return "mdi-check-circle";
      }
      return "";
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-height: 24px !important;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
.normal {
  -ms-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
</style>